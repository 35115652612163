import { PlatformApi } from '~/services/api/platform'

export class LinkService extends PlatformApi {
  async create() {
    return await this.post(`/link`)
  }

  getAll() {
    return this.get(`/link`)
  }

  getActive() {
    return this.get(`/link/active`)
  }

  async update(id) {
    return await this.put(`/link/${id}`)
  }

  async deleteLink(id) {
    return await this.delete(`/link/${id}`)
  }

  logClick(id) {
    return this.post(`/link/${id}/click`)
  }

  async updateOrder(order) {
    return await this.put(`/link/order`, { order })
  }
}

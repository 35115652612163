import capitalize from 'lodash.capitalize'
import { Ability, AbilityBuilder } from '@casl/ability'

const resources = [
  'register',
  'bookings',
  'profile',
  'content',
  'sharing',
  'maintenance',
  'staffDashboard',
  'staffCustomers',
  'staffOrganisations',
  'staffBookings',
  'staffCalender',
  'staffDiscounts',
  'staffReports',
  'staffReportsRevenue',
  'staffCredit',
  'staffUserImpersonate',
  'staffSystemSettings',
  'staffCustomerMerge',
  'paddocks',
  'staffMap',
  'staffTeam',
  'staffMaintenance',
  'staffAnnouncements',
  'staffGlobalBanner',
  'staffReplays',
  'staffSuper',
  'staffAdverts',
  'staffLinks'
]

export const roleConfig = {
  OWNER: {
    value: 'OWNER',
    label: 'Owner',
    translationKey: 'roles.owner',
    description: 'Can manage everything and share with new users',
    color: 'red',
    permissions: function ({ can }, user) {
      can('read', 'paddocks')
      can('manage', 'profile')
      can('create', 'bookings')
      can('read', 'bookings')
    }
  },
  STAFF_MAINTENANCE: {
    isStaffRole: true,
    value: 'STAFF_MAINTENANCE',
    label: 'Staff maintenance',
    translationKey: 'roles.maintenance',
    description: 'View calendar and cancel bookings for urgent maintenance',
    color: 'green',
    permissions: function ({ can }, user) {
      can('manage', 'maintenance')
      can('read', 'staffCalender')
      can('create', 'bookings')
      can('manage', 'profile')
      can('create', 'content')
      can('delete', 'content')
      can('read', 'staffCustomers')
      can('read', 'staffOrganisations')
      can('read', 'sharing')
      can('read', 'paddocks')
      can('read', 'staffMaintenance')
      can('create', 'staffMaintenance')
      can('manage', 'staffBookings')
    }
  },
  STAFF_MARKETING: {
    isStaffRole: true,
    value: 'STAFF_MARKETING',
    label: 'Staff marketing',
    translationKey: 'roles.staffMarketing',
    description: 'Calendar, Customers, Paddocks, Discounts, Content, Map',
    color: 'green',
    permissions: function ({ can }, user) {
      can('read', 'staffCalender')
      can('create', 'bookings')
      can('manage', 'profile')
      can('manage', 'staffCustomers')
      can('manage', 'staffOrganisations')
      can('manage', 'paddocks')
      can('manage', 'staffBookings')
      can('manage', 'staffDiscounts')
      can('manage', 'content')
      can('manage', 'staffCustomerMerge')
      can('manage', 'staffUserImpersonate')
      can('manage', 'staffCredit')
      can('manage', 'staffMaintenance')
      can('manage', 'staffAnnouncements')
      can('manage', 'staffGlobalBanner')
      can('read', 'sharing')
      can('read', 'staffMap')
      can('manage', 'staffReplays')
      can('read', 'staffReports')
      can('read', 'staffDashboard')
      can('manage', 'staffAdverts')
      can('manage', 'staffLinks')
    }
  },
  STAFF_CUSTOMER_SERVICE: {
    isStaffRole: true,
    value: 'STAFF_CUSTOMER_SERVICE',
    label: 'Staff customer service',
    translationKey: 'roles.staffCustomerService',
    description: 'Calendar, Customers, Discounts',
    color: 'yellow',
    permissions: function ({ can }, user) {
      can('read', 'staffCalender')
      can('create', 'bookings')
      can('manage', 'profile')
      can('manage', 'staffCustomers')
      can('manage', 'staffOrganisations')
      can('read', 'paddocks')
      can('manage', 'staffBookings')
      can('manage', 'staffDiscounts')
      can('manage', 'staffCustomerMerge')
      can('manage', 'staffUserImpersonate')
      can('manage', 'staffMaintenance')
      can('manage', 'staffCredit')
      can('manage', 'staffAnnouncements')
      can('manage', 'staffGlobalBanner')
      can('read', 'sharing')
      can('manage', 'staffReplays')
      can('read', 'staffDashboard')
      can('read', 'staffMap')
      can('manage', 'staffAdverts')
      can('manage', 'staffLinks')
    }
  },
  STAFF_ADMIN: {
    isStaffRole: true,
    value: 'STAFF_ADMIN',
    label: 'Staff admin',
    translationKey: 'roles.staffAdmin',
    description: 'Full access to everything',
    color: 'red',
    permissions: function ({ can }, user) {
      resources
        .filter(resource => resource !== 'staffSuper')
        .forEach(resource => {
          can('manage', resource)
        })
    }
  },
  STAFF_SUPER_ADMIN: {
    isStaffRole: true,
    isSuperStaffRole: true,
    value: 'STAFF_SUPER_ADMIN',
    label: 'Staff super admin',
    translationKey: 'roles.staffSuperAdmin',
    description: 'Full access to everything',
    color: 'red',
    permissions: function ({ can }, user) {
      resources.forEach(resource => {
        can('manage', resource)
      })
    }
  }
}

export function loadAbilityFromJSON(permissions = []) {
  return new Ability(permissions)
}

export function defineAbilityFor(user) {
  if (user) {
    return new Ability(defineRulesFor(user))
  }

  // Guest user
  return new Ability(defineRulesFor({}))
}

export function defineRulesFor(user) {
  const builder = new AbilityBuilder(Ability)
  const { can } = builder

  if (user?.currentOrganisation?.role) {
    roleConfig[user.currentOrganisation.role].permissions(builder, user)
  } else {
    can('create', 'register')
  }

  return builder.rules
}

export const roles = Object.fromEntries(
  Object.entries(roleConfig).map(([roleKey, config]) => [roleKey, config.value])
)

export const staffRoles = Object.keys(roles).filter(roleKey => roles[roleKey].isStaffRole)

export const standardOrgRoles = Object.keys(roles).filter(roleKey => roles[roleKey].isStaffRole)

export const findRoleConfigByValue = value => {
  return Object.values(roleConfig).find(role => role.value === value)
}

export function getRoleOptions({ isStaff = false } = {}) {
  return Object.keys(roleConfig)
    .filter(roleKey =>
      isStaff
        ? roleConfig[roleKey].isStaffRole && !roleConfig[roleKey].isSuperStaffRole
        : !roleConfig[roleKey].isStaffRole
    )
    .map(roleKey => ({
      value: roleConfig[roleKey].value,
      // TODO: Return translation key for FE instead
      label: roleConfig[roleKey].label,
      description: roleConfig[roleKey].description
    }))
}


import { faSpinner } from '@fortawesome/pro-solid-svg-icons'

export default {
  name: 'Button',

  props: {
    tag: {
      type: String,
      default: 'button'
    },

    type: {
      type: String,
      default: 'button',
      validator: prop => ['button', 'submit', 'reset', 'black'].includes(prop)
    },

    priority: {
      type: String,
      default: 'default',
      validator: prop =>
        ['default', 'primary', 'secondary', 'caution', 'error', 'transparent', 'selected', 'black'].includes(prop)
    },

    size: {
      type: String,
      default: 'md',
      validator: prop => ['xs', 'sm', 'md', 'lg', 'xl'].includes(prop)
    },

    to: {
      type: String,
      default: null
    },

    isLoading: {
      type: Boolean,
      default: false
    },

    isDisabled: {
      type: Boolean,
      default: false
    },

    // Overrides prefixIcon
    prefixText: {
      type: String,
      default: null
    },

    prefixIcon: {
      type: Object,
      default: null
    },

    suffixText: {
      type: String,
      default: null
    },

    suffixIcon: {
      type: Object,
      default: null
    },

    tooltip: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      faSpinner
    }
  },

  computed: {
    compIsPrimary() {
      return this.priority === 'primary'
    },

    compClass() {
      return [
        {
          [this.priority]: true,
          [this.size]: true,
          loading: this.isLoading,
          disabled: this.isDisabled || this.isLoading,
          'icon-only': this.prefixIcon && !this.$slots.default
        },
        this.compIsPrimary ? 'text-white' : 'text-body'
        // this.size === 'xs' ? 'rounded' : 'rounded-md'
      ]
    },

    compHasPrefix() {
      return !!this.prefixIcon || !!this.prefixText || !!this.$slots.prefix
    },

    compHasSuffix() {
      return !!this.suffixIcon || !!this.suffixText || !!this.$slots.suffix
    }
  },

  methods: {
    onButtonClick() {
      this.$emit('click')

      if (this.to && this.to.includes('tel:')) {
        document.location.href = this.to
      } else if (this.to) {
        this.$router.push(this.localePath(this.to))
      }
    }
  }
}

export const BannerAdvert = () => import('../../src/components/banners/BannerAdvert.vue' /* webpackChunkName: "components/banner-advert" */).then(c => wrapFunctional(c.default || c))
export const BannerCredit = () => import('../../src/components/banners/BannerCredit.vue' /* webpackChunkName: "components/banner-credit" */).then(c => wrapFunctional(c.default || c))
export const BannerGlobal = () => import('../../src/components/banners/BannerGlobal.vue' /* webpackChunkName: "components/banner-global" */).then(c => wrapFunctional(c.default || c))
export const BannerPushNotifications = () => import('../../src/components/banners/BannerPushNotifications.vue' /* webpackChunkName: "components/banner-push-notifications" */).then(c => wrapFunctional(c.default || c))
export const BannerVerification = () => import('../../src/components/banners/BannerVerification.vue' /* webpackChunkName: "components/banner-verification" */).then(c => wrapFunctional(c.default || c))
export const NavItems = () => import('../../src/components/nav/NavItems.vue' /* webpackChunkName: "components/nav-items" */).then(c => wrapFunctional(c.default || c))
export const Cards = () => import('../../src/components/elements/account/cards.vue' /* webpackChunkName: "components/cards" */).then(c => wrapFunctional(c.default || c))
export const Notifications = () => import('../../src/components/elements/account/notifications.vue' /* webpackChunkName: "components/notifications" */).then(c => wrapFunctional(c.default || c))
export const Profile = () => import('../../src/components/elements/account/profile.vue' /* webpackChunkName: "components/profile" */).then(c => wrapFunctional(c.default || c))
export const Security = () => import('../../src/components/elements/account/security.vue' /* webpackChunkName: "components/security" */).then(c => wrapFunctional(c.default || c))
export const Announcement = () => import('../../src/components/elements/announcement/Announcement.vue' /* webpackChunkName: "components/announcement" */).then(c => wrapFunctional(c.default || c))
export const Booking = () => import('../../src/components/elements/bookings/Booking.vue' /* webpackChunkName: "components/booking" */).then(c => wrapFunctional(c.default || c))
export const Order = () => import('../../src/components/elements/bookings/Order.vue' /* webpackChunkName: "components/order" */).then(c => wrapFunctional(c.default || c))
export const Contact = () => import('../../src/components/elements/contact/Contact.vue' /* webpackChunkName: "components/contact" */).then(c => wrapFunctional(c.default || c))
export const Faqs = () => import('../../src/components/elements/contact/Faqs.vue' /* webpackChunkName: "components/faqs" */).then(c => wrapFunctional(c.default || c))
export const ContentButton = () => import('../../src/components/elements/content-editor/ContentButton.vue' /* webpackChunkName: "components/content-button" */).then(c => wrapFunctional(c.default || c))
export const ContentEditor = () => import('../../src/components/elements/content-editor/ContentEditor.vue' /* webpackChunkName: "components/content-editor" */).then(c => wrapFunctional(c.default || c))
export const Iframe = () => import('../../src/components/elements/content-editor/iframe.js' /* webpackChunkName: "components/iframe" */).then(c => wrapFunctional(c.default || c))
export const ImageResize = () => import('../../src/components/elements/content-editor/image-resize.js' /* webpackChunkName: "components/image-resize" */).then(c => wrapFunctional(c.default || c))
export const LinkTreeItem = () => import('../../src/components/elements/link-tree/LinkTreeItem.vue' /* webpackChunkName: "components/link-tree-item" */).then(c => wrapFunctional(c.default || c))
export const NewsList = () => import('../../src/components/elements/news/NewsList.vue' /* webpackChunkName: "components/news-list" */).then(c => wrapFunctional(c.default || c))
export const Shop = () => import('../../src/components/elements/shop/Shop.vue' /* webpackChunkName: "components/shop" */).then(c => wrapFunctional(c.default || c))
export const SocialLinks = () => import('../../src/components/elements/social/SocialLinks.vue' /* webpackChunkName: "components/social-links" */).then(c => wrapFunctional(c.default || c))
export const GiftVoucher = () => import('../../src/components/elements/vouchers/GiftVoucher.vue' /* webpackChunkName: "components/gift-voucher" */).then(c => wrapFunctional(c.default || c))
export const FormAdvert = () => import('../../src/components/forms/advert/FormAdvert.vue' /* webpackChunkName: "components/form-advert" */).then(c => wrapFunctional(c.default || c))
export const FormAnnouncement = () => import('../../src/components/forms/announcement/FormAnnouncement.vue' /* webpackChunkName: "components/form-announcement" */).then(c => wrapFunctional(c.default || c))
export const FormGlobalBanner = () => import('../../src/components/forms/announcement/FormGlobalBanner.vue' /* webpackChunkName: "components/form-global-banner" */).then(c => wrapFunctional(c.default || c))
export const FormChangePassword = () => import('../../src/components/forms/auth/FormChangePassword.vue' /* webpackChunkName: "components/form-change-password" */).then(c => wrapFunctional(c.default || c))
export const FormForgottenPassword = () => import('../../src/components/forms/auth/FormForgottenPassword.vue' /* webpackChunkName: "components/form-forgotten-password" */).then(c => wrapFunctional(c.default || c))
export const FormLogin = () => import('../../src/components/forms/auth/FormLogin.vue' /* webpackChunkName: "components/form-login" */).then(c => wrapFunctional(c.default || c))
export const FormResetPassword = () => import('../../src/components/forms/auth/FormResetPassword.vue' /* webpackChunkName: "components/form-reset-password" */).then(c => wrapFunctional(c.default || c))
export const FormBlockBooking = () => import('../../src/components/forms/booking/FormBlockBooking.vue' /* webpackChunkName: "components/form-block-booking" */).then(c => wrapFunctional(c.default || c))
export const FormBooking = () => import('../../src/components/forms/booking/FormBooking.vue' /* webpackChunkName: "components/form-booking" */).then(c => wrapFunctional(c.default || c))
export const FormContact = () => import('../../src/components/forms/contact/FormContact.vue' /* webpackChunkName: "components/form-contact" */).then(c => wrapFunctional(c.default || c))
export const FormFAQ = () => import('../../src/components/forms/content/FormFAQ.vue' /* webpackChunkName: "components/form-f-a-q" */).then(c => wrapFunctional(c.default || c))
export const FormNewsPost = () => import('../../src/components/forms/content/FormNewsPost.vue' /* webpackChunkName: "components/form-news-post" */).then(c => wrapFunctional(c.default || c))
export const FormDiscount = () => import('../../src/components/forms/discount/FormDiscount.vue' /* webpackChunkName: "components/form-discount" */).then(c => wrapFunctional(c.default || c))
export const FormLandOwner = () => import('../../src/components/forms/land-owner/FormLandOwner.vue' /* webpackChunkName: "components/form-land-owner" */).then(c => wrapFunctional(c.default || c))
export const FormLink = () => import('../../src/components/forms/link/FormLink.vue' /* webpackChunkName: "components/form-link" */).then(c => wrapFunctional(c.default || c))
export const FormJob = () => import('../../src/components/forms/maintenance/FormJob.vue' /* webpackChunkName: "components/form-job" */).then(c => wrapFunctional(c.default || c))
export const FormNotificationPreferences = () => import('../../src/components/forms/notifications/FormNotificationPreferences.vue' /* webpackChunkName: "components/form-notification-preferences" */).then(c => wrapFunctional(c.default || c))
export const FormPaddock = () => import('../../src/components/forms/paddock/FormPaddock.vue' /* webpackChunkName: "components/form-paddock" */).then(c => wrapFunctional(c.default || c))
export const FormMaintenanceModeSettings = () => import('../../src/components/forms/system/FormMaintenanceModeSettings.vue' /* webpackChunkName: "components/form-maintenance-mode-settings" */).then(c => wrapFunctional(c.default || c))
export const FormTeam = () => import('../../src/components/forms/team/FormTeam.vue' /* webpackChunkName: "components/form-team" */).then(c => wrapFunctional(c.default || c))
export const FormNewUser = () => import('../../src/components/forms/user/FormNewUser.vue' /* webpackChunkName: "components/form-new-user" */).then(c => wrapFunctional(c.default || c))
export const FormProfile = () => import('../../src/components/forms/user/FormProfile.vue' /* webpackChunkName: "components/form-profile" */).then(c => wrapFunctional(c.default || c))
export const FormVoucher = () => import('../../src/components/forms/voucher/FormVoucher.vue' /* webpackChunkName: "components/form-voucher" */).then(c => wrapFunctional(c.default || c))
export const FormVoucherImage = () => import('../../src/components/forms/voucher/FormVoucherImage.vue' /* webpackChunkName: "components/form-voucher-image" */).then(c => wrapFunctional(c.default || c))
export const GuardAuth = () => import('../../src/components/guards/auth/GuardAuth.vue' /* webpackChunkName: "components/guard-auth" */).then(c => wrapFunctional(c.default || c))
export const Guard = () => import('../../src/components/guards/guard/Guard.vue' /* webpackChunkName: "components/guard" */).then(c => wrapFunctional(c.default || c))
export const AppLoader = () => import('../../src/components/layouts/app-loader/AppLoader.vue' /* webpackChunkName: "components/app-loader" */).then(c => wrapFunctional(c.default || c))
export const LayoutAuth = () => import('../../src/components/layouts/auth/LayoutAuth.vue' /* webpackChunkName: "components/layout-auth" */).then(c => wrapFunctional(c.default || c))
export const Brochureware = () => import('../../src/components/layouts/brochureware/Brochureware.vue' /* webpackChunkName: "components/brochureware" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooter = () => import('../../src/components/layouts/footer/LayoutFooter.vue' /* webpackChunkName: "components/layout-footer" */).then(c => wrapFunctional(c.default || c))
export const ModalBase = () => import('../../src/components/modals/modal-base/ModalBase.vue' /* webpackChunkName: "components/modal-base" */).then(c => wrapFunctional(c.default || c))
export const ModalWrapper = () => import('../../src/components/modals/modal-wrapper/ModalWrapper.vue' /* webpackChunkName: "components/modal-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ApiData = () => import('../../src/components/utils/api-data/ApiData.vue' /* webpackChunkName: "components/api-data" */).then(c => wrapFunctional(c.default || c))
export const ApiLoadMore = () => import('../../src/components/utils/api-load-more/ApiLoadMore.vue' /* webpackChunkName: "components/api-load-more" */).then(c => wrapFunctional(c.default || c))
export const DevDebug = () => import('../../src/components/utils/dev-debug/DevDebug.vue' /* webpackChunkName: "components/dev-debug" */).then(c => wrapFunctional(c.default || c))
export const IframeEmbed = () => import('../../src/components/utils/iframe-embed/IframeEmbed.vue' /* webpackChunkName: "components/iframe-embed" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../src/components/utils/loader/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const ScriptLoader = () => import('../../src/components/utils/script-loader/ScriptLoader.vue' /* webpackChunkName: "components/script-loader" */).then(c => wrapFunctional(c.default || c))
export const ActiveDataFilters = () => import('../../src/components/elements/admin/active-data-filters/ActiveDataFilters.vue' /* webpackChunkName: "components/active-data-filters" */).then(c => wrapFunctional(c.default || c))
export const BookingCalendar = () => import('../../src/components/elements/admin/booking-calendar/BookingCalendar.vue' /* webpackChunkName: "components/booking-calendar" */).then(c => wrapFunctional(c.default || c))
export const GlobalBannerAdmin = () => import('../../src/components/elements/admin/global-banner-admin/GlobalBannerAdmin.vue' /* webpackChunkName: "components/global-banner-admin" */).then(c => wrapFunctional(c.default || c))
export const AdminSectionHeader = () => import('../../src/components/elements/admin/section-header/AdminSectionHeader.vue' /* webpackChunkName: "components/admin-section-header" */).then(c => wrapFunctional(c.default || c))
export const AdminUserCard = () => import('../../src/components/elements/admin/user-card/AdminUserCard.vue' /* webpackChunkName: "components/admin-user-card" */).then(c => wrapFunctional(c.default || c))
export const Accordion = () => import('../../src/components/elements/base/accordion/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const AccordionCard = () => import('../../src/components/elements/base/accordion/AccordionCard.vue' /* webpackChunkName: "components/accordion-card" */).then(c => wrapFunctional(c.default || c))
export const AccordionList = () => import('../../src/components/elements/base/accordion/AccordionList.vue' /* webpackChunkName: "components/accordion-list" */).then(c => wrapFunctional(c.default || c))
export const AppWrapper = () => import('../../src/components/elements/base/app-wrapper/AppWrapper.vue' /* webpackChunkName: "components/app-wrapper" */).then(c => wrapFunctional(c.default || c))
export const Avatar = () => import('../../src/components/elements/base/avatar/Avatar.vue' /* webpackChunkName: "components/avatar" */).then(c => wrapFunctional(c.default || c))
export const AvatarPlaceholder = () => import('../../src/components/elements/base/avatar/AvatarPlaceholder.vue' /* webpackChunkName: "components/avatar-placeholder" */).then(c => wrapFunctional(c.default || c))
export const BadgeCount = () => import('../../src/components/elements/base/badge-count/BadgeCount.vue' /* webpackChunkName: "components/badge-count" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../src/components/elements/base/banner/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const BottomSheet = () => import('../../src/components/elements/base/bottom-sheet/BottomSheet.vue' /* webpackChunkName: "components/bottom-sheet" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumbs = () => import('../../src/components/elements/base/breadcrumbs/Breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../src/components/elements/base/button/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const ButtonDropdown = () => import('../../src/components/elements/base/button-dropdown/ButtonDropdown.vue' /* webpackChunkName: "components/button-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ButtonSwipe = () => import('../../src/components/elements/base/button-swipe/ButtonSwipe.vue' /* webpackChunkName: "components/button-swipe" */).then(c => wrapFunctional(c.default || c))
export const ChartBar = () => import('../../src/components/elements/base/chart/ChartBar.vue' /* webpackChunkName: "components/chart-bar" */).then(c => wrapFunctional(c.default || c))
export const ChartLine = () => import('../../src/components/elements/base/chart/ChartLine.vue' /* webpackChunkName: "components/chart-line" */).then(c => wrapFunctional(c.default || c))
export const ContactCard = () => import('../../src/components/elements/base/contact-card/ContactCard.vue' /* webpackChunkName: "components/contact-card" */).then(c => wrapFunctional(c.default || c))
export const ContactNumber = () => import('../../src/components/elements/base/contact-number/ContactNumber.vue' /* webpackChunkName: "components/contact-number" */).then(c => wrapFunctional(c.default || c))
export const Content = () => import('../../src/components/elements/base/content/Content.vue' /* webpackChunkName: "components/content" */).then(c => wrapFunctional(c.default || c))
export const ContentSwitcher = () => import('../../src/components/elements/base/content-switcher/ContentSwitcher.vue' /* webpackChunkName: "components/content-switcher" */).then(c => wrapFunctional(c.default || c))
export const DateDisplay = () => import('../../src/components/elements/base/date-display/DateDisplay.vue' /* webpackChunkName: "components/date-display" */).then(c => wrapFunctional(c.default || c))
export const DevSiteBanner = () => import('../../src/components/elements/base/dev-site-banner/DevSiteBanner.vue' /* webpackChunkName: "components/dev-site-banner" */).then(c => wrapFunctional(c.default || c))
export const DistanceDisplay = () => import('../../src/components/elements/base/distance-display/DistanceDisplay.vue' /* webpackChunkName: "components/distance-display" */).then(c => wrapFunctional(c.default || c))
export const Divider = () => import('../../src/components/elements/base/divider/Divider.vue' /* webpackChunkName: "components/divider" */).then(c => wrapFunctional(c.default || c))
export const DropdownMenuList = () => import('../../src/components/elements/base/dropdown-menu-list/DropdownMenuList.vue' /* webpackChunkName: "components/dropdown-menu-list" */).then(c => wrapFunctional(c.default || c))
export const MobileDropdownMenuOverlay = () => import('../../src/components/elements/base/dropdown-menu-list/MobileDropdownMenuOverlay.vue' /* webpackChunkName: "components/mobile-dropdown-menu-overlay" */).then(c => wrapFunctional(c.default || c))
export const Empty = () => import('../../src/components/elements/base/empty/Empty.vue' /* webpackChunkName: "components/empty" */).then(c => wrapFunctional(c.default || c))
export const Icon = () => import('../../src/components/elements/base/icon/Icon.vue' /* webpackChunkName: "components/icon" */).then(c => wrapFunctional(c.default || c))
export const Img = () => import('../../src/components/elements/base/image/Img.vue' /* webpackChunkName: "components/img" */).then(c => wrapFunctional(c.default || c))
export const ImageCropper = () => import('../../src/components/elements/base/image-cropper/ImageCropper.vue' /* webpackChunkName: "components/image-cropper" */).then(c => wrapFunctional(c.default || c))
export const Link = () => import('../../src/components/elements/base/link/Link.vue' /* webpackChunkName: "components/link" */).then(c => wrapFunctional(c.default || c))
export const ListLink = () => import('../../src/components/elements/base/list-link/ListLink.vue' /* webpackChunkName: "components/list-link" */).then(c => wrapFunctional(c.default || c))
export const AccountNotification = () => import('../../src/components/elements/base/notification/AccountNotification.vue' /* webpackChunkName: "components/account-notification" */).then(c => wrapFunctional(c.default || c))
export const AccountNotificationWrapper = () => import('../../src/components/elements/base/notification/AccountNotificationWrapper.vue' /* webpackChunkName: "components/account-notification-wrapper" */).then(c => wrapFunctional(c.default || c))
export const Notification = () => import('../../src/components/elements/base/notification/Notification.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c))
export const NotificationFloating = () => import('../../src/components/elements/base/notification/NotificationFloating.vue' /* webpackChunkName: "components/notification-floating" */).then(c => wrapFunctional(c.default || c))
export const NotificationFloatingWrapper = () => import('../../src/components/elements/base/notification/NotificationFloatingWrapper.vue' /* webpackChunkName: "components/notification-floating-wrapper" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../src/components/elements/base/pagination/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const Panel = () => import('../../src/components/elements/base/panel/Panel.vue' /* webpackChunkName: "components/panel" */).then(c => wrapFunctional(c.default || c))
export const Payment = () => import('../../src/components/elements/base/payment/Payment.vue' /* webpackChunkName: "components/payment" */).then(c => wrapFunctional(c.default || c))
export const Placeholder = () => import('../../src/components/elements/base/placeholder/Placeholder.vue' /* webpackChunkName: "components/placeholder" */).then(c => wrapFunctional(c.default || c))
export const Popover = () => import('../../src/components/elements/base/popover/Popover.vue' /* webpackChunkName: "components/popover" */).then(c => wrapFunctional(c.default || c))
export const ProgressBar = () => import('../../src/components/elements/base/progress-bar/ProgressBar.vue' /* webpackChunkName: "components/progress-bar" */).then(c => wrapFunctional(c.default || c))
export const SelectList = () => import('../../src/components/elements/base/select-list/SelectList.vue' /* webpackChunkName: "components/select-list" */).then(c => wrapFunctional(c.default || c))
export const SelectListItem = () => import('../../src/components/elements/base/select-list/SelectListItem.vue' /* webpackChunkName: "components/select-list-item" */).then(c => wrapFunctional(c.default || c))
export const SelectTileItem = () => import('../../src/components/elements/base/select-tile-item/SelectTileItem.vue' /* webpackChunkName: "components/select-tile-item" */).then(c => wrapFunctional(c.default || c))
export const Slider = () => import('../../src/components/elements/base/slider/Slider.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c))
export const Stat = () => import('../../src/components/elements/base/stat/Stat.vue' /* webpackChunkName: "components/stat" */).then(c => wrapFunctional(c.default || c))
export const StatusTag = () => import('../../src/components/elements/base/status-tag/StatusTag.vue' /* webpackChunkName: "components/status-tag" */).then(c => wrapFunctional(c.default || c))
export const Table = () => import('../../src/components/elements/base/table/Table.vue' /* webpackChunkName: "components/table" */).then(c => wrapFunctional(c.default || c))
export const Tabs = () => import('../../src/components/elements/base/tabs/Tabs.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))
export const Tag = () => import('../../src/components/elements/base/tag/Tag.vue' /* webpackChunkName: "components/tag" */).then(c => wrapFunctional(c.default || c))
export const ToolTip = () => import('../../src/components/elements/base/tooltip/ToolTip.vue' /* webpackChunkName: "components/tool-tip" */).then(c => wrapFunctional(c.default || c))
export const TotalPill = () => import('../../src/components/elements/base/total-pill/TotalPill.vue' /* webpackChunkName: "components/total-pill" */).then(c => wrapFunctional(c.default || c))
export const WeightDisplay = () => import('../../src/components/elements/base/weight-display/WeightDisplay.vue' /* webpackChunkName: "components/weight-display" */).then(c => wrapFunctional(c.default || c))
export const Basket = () => import('../../src/components/elements/book/basket/Basket.vue' /* webpackChunkName: "components/basket" */).then(c => wrapFunctional(c.default || c))
export const Book = () => import('../../src/components/elements/book/book/Book.vue' /* webpackChunkName: "components/book" */).then(c => wrapFunctional(c.default || c))
export const BookBrochureware = () => import('../../src/components/elements/book/book-brochureware/BookBrochureware.vue' /* webpackChunkName: "components/book-brochureware" */).then(c => wrapFunctional(c.default || c))
export const Checkout = () => import('../../src/components/elements/book/checkout/Checkout.vue' /* webpackChunkName: "components/checkout" */).then(c => wrapFunctional(c.default || c))
export const BookingComplete = () => import('../../src/components/elements/book/complete/BookingComplete.vue' /* webpackChunkName: "components/booking-complete" */).then(c => wrapFunctional(c.default || c))
export const PurchasingBlocker = () => import('../../src/components/elements/book/purchasing-block/PurchasingBlocker.vue' /* webpackChunkName: "components/purchasing-blocker" */).then(c => wrapFunctional(c.default || c))
export const BookingDashboard = () => import('../../src/components/elements/bookings/booking-dashboard/BookingDashboard.vue' /* webpackChunkName: "components/booking-dashboard" */).then(c => wrapFunctional(c.default || c))
export const BookingDate = () => import('../../src/components/elements/bookings/booking-date/BookingDate.vue' /* webpackChunkName: "components/booking-date" */).then(c => wrapFunctional(c.default || c))
export const BookingsList = () => import('../../src/components/elements/bookings/bookings-list/BookingsList.vue' /* webpackChunkName: "components/bookings-list" */).then(c => wrapFunctional(c.default || c))
export const Currency = () => import('../../src/components/elements/financial/currency-display/Currency.vue' /* webpackChunkName: "components/currency" */).then(c => wrapFunctional(c.default || c))
export const DatePicker = () => import('../../src/components/elements/form/date-picker/DatePicker.vue' /* webpackChunkName: "components/date-picker" */).then(c => wrapFunctional(c.default || c))
export const ErrorSummary = () => import('../../src/components/elements/form/error-summary/ErrorSummary.vue' /* webpackChunkName: "components/error-summary" */).then(c => wrapFunctional(c.default || c))
export const Field = () => import('../../src/components/elements/form/field/Field.vue' /* webpackChunkName: "components/field" */).then(c => wrapFunctional(c.default || c))
export const FileUploadCore = () => import('../../src/components/elements/form/file-upload/FileUploadCore.vue' /* webpackChunkName: "components/file-upload-core" */).then(c => wrapFunctional(c.default || c))
export const FileUploadInput = () => import('../../src/components/elements/form/file-upload/FileUploadInput.vue' /* webpackChunkName: "components/file-upload-input" */).then(c => wrapFunctional(c.default || c))
export const FileUploadList = () => import('../../src/components/elements/form/file-upload/FileUploadList.vue' /* webpackChunkName: "components/file-upload-list" */).then(c => wrapFunctional(c.default || c))
export const FileUploadListItem = () => import('../../src/components/elements/form/file-upload/FileUploadListItem.vue' /* webpackChunkName: "components/file-upload-list-item" */).then(c => wrapFunctional(c.default || c))
export const Form = () => import('../../src/components/elements/form/form/Form.vue' /* webpackChunkName: "components/form" */).then(c => wrapFunctional(c.default || c))
export const FormStep = () => import('../../src/components/elements/form/form-step/FormStep.vue' /* webpackChunkName: "components/form-step" */).then(c => wrapFunctional(c.default || c))
export const Input = () => import('../../src/components/elements/form/input/Input.vue' /* webpackChunkName: "components/input" */).then(c => wrapFunctional(c.default || c))
export const InputLinks = () => import('../../src/components/elements/form/input-links/InputLinks.vue' /* webpackChunkName: "components/input-links" */).then(c => wrapFunctional(c.default || c))
export const Label = () => import('../../src/components/elements/form/label/Label.vue' /* webpackChunkName: "components/label" */).then(c => wrapFunctional(c.default || c))
export const MultiSelect = () => import('../../src/components/elements/form/multi-select/MultiSelect.vue' /* webpackChunkName: "components/multi-select" */).then(c => wrapFunctional(c.default || c))
export const Select = () => import('../../src/components/elements/form/select/Select.vue' /* webpackChunkName: "components/select" */).then(c => wrapFunctional(c.default || c))
export const TagInput = () => import('../../src/components/elements/form/tags/TagInput.vue' /* webpackChunkName: "components/tag-input" */).then(c => wrapFunctional(c.default || c))
export const TextArea = () => import('../../src/components/elements/form/text-area/TextArea.vue' /* webpackChunkName: "components/text-area" */).then(c => wrapFunctional(c.default || c))
export const TimePicker = () => import('../../src/components/elements/form/time-picker/TimePicker.vue' /* webpackChunkName: "components/time-picker" */).then(c => wrapFunctional(c.default || c))
export const Toggle = () => import('../../src/components/elements/form/toggle/Toggle.vue' /* webpackChunkName: "components/toggle" */).then(c => wrapFunctional(c.default || c))
export const Address = () => import('../../src/components/elements/geo/address/Address.vue' /* webpackChunkName: "components/address" */).then(c => wrapFunctional(c.default || c))
export const AddressLocalityRegion = () => import('../../src/components/elements/geo/address-locality-region/AddressLocalityRegion.vue' /* webpackChunkName: "components/address-locality-region" */).then(c => wrapFunctional(c.default || c))
export const CurrentLocationAnimation = () => import('../../src/components/elements/geo/current-location-animation/CurrentLocationAnimation.vue' /* webpackChunkName: "components/current-location-animation" */).then(c => wrapFunctional(c.default || c))
export const Flag = () => import('../../src/components/elements/geo/flag/Flag.vue' /* webpackChunkName: "components/flag" */).then(c => wrapFunctional(c.default || c))
export const LocaleSwitcher = () => import('../../src/components/elements/geo/locale-switcher/LocaleSwitcher.vue' /* webpackChunkName: "components/locale-switcher" */).then(c => wrapFunctional(c.default || c))
export const LocationArrow = () => import('../../src/components/elements/geo/location-arrow/LocationArrow.vue' /* webpackChunkName: "components/location-arrow" */).then(c => wrapFunctional(c.default || c))
export const Map = () => import('../../src/components/elements/geo/map/Map.vue' /* webpackChunkName: "components/map" */).then(c => wrapFunctional(c.default || c))
export const MapImage = () => import('../../src/components/elements/geo/map/MapImage.vue' /* webpackChunkName: "components/map-image" */).then(c => wrapFunctional(c.default || c))
export const CookieBanner = () => import('../../src/components/elements/legal/cookie/CookieBanner.vue' /* webpackChunkName: "components/cookie-banner" */).then(c => wrapFunctional(c.default || c))
export const PrivacyPolicy = () => import('../../src/components/elements/legal/privacy/PrivacyPolicy.vue' /* webpackChunkName: "components/privacy-policy" */).then(c => wrapFunctional(c.default || c))
export const TermsAndConditions = () => import('../../src/components/elements/legal/terms-and-conditions/TermsAndConditions.vue' /* webpackChunkName: "components/terms-and-conditions" */).then(c => wrapFunctional(c.default || c))
export const PaddockFinder = () => import('../../src/components/elements/paddocks/paddock-finder/PaddockFinder.vue' /* webpackChunkName: "components/paddock-finder" */).then(c => wrapFunctional(c.default || c))
export const PaddockSingle = () => import('../../src/components/elements/paddocks/paddock-single/PaddockSingle.vue' /* webpackChunkName: "components/paddock-single" */).then(c => wrapFunctional(c.default || c))
export const Heading = () => import('../../src/components/elements/typography/heading/Heading.vue' /* webpackChunkName: "components/heading" */).then(c => wrapFunctional(c.default || c))
export const NativeStatusBar = () => import('../../src/components/layouts/native/status-bar/NativeStatusBar.vue' /* webpackChunkName: "components/native-status-bar" */).then(c => wrapFunctional(c.default || c))
export const LayoutAdminContentHeader = () => import('../../src/components/layouts/shell/content-header/LayoutAdminContentHeader.vue' /* webpackChunkName: "components/layout-admin-content-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutAdminDataIndex = () => import('../../src/components/layouts/shell/data-index/LayoutAdminDataIndex.vue' /* webpackChunkName: "components/layout-admin-data-index" */).then(c => wrapFunctional(c.default || c))
export const LayoutPlatformHeader = () => import('../../src/components/layouts/shell/header/LayoutPlatformHeader.vue' /* webpackChunkName: "components/layout-platform-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutPlatformHeaderMobile = () => import('../../src/components/layouts/shell/header/LayoutPlatformHeaderMobile.vue' /* webpackChunkName: "components/layout-platform-header-mobile" */).then(c => wrapFunctional(c.default || c))
export const LayoutPlatformShell = () => import('../../src/components/layouts/shell/shell/LayoutPlatformShell.vue' /* webpackChunkName: "components/layout-platform-shell" */).then(c => wrapFunctional(c.default || c))
export const LayoutAdminSidebar = () => import('../../src/components/layouts/shell/sidebar/LayoutAdminSidebar.vue' /* webpackChunkName: "components/layout-admin-sidebar" */).then(c => wrapFunctional(c.default || c))
export const ModalAdminAdvert = () => import('../../src/components/modals/admin/admin-advert/ModalAdminAdvert.vue' /* webpackChunkName: "components/modal-admin-advert" */).then(c => wrapFunctional(c.default || c))
export const ModalAdminBlockBook = () => import('../../src/components/modals/admin/admin-block-book/ModalAdminBlockBook.vue' /* webpackChunkName: "components/modal-admin-block-book" */).then(c => wrapFunctional(c.default || c))
export const ModalAdminBook = () => import('../../src/components/modals/admin/admin-book/ModalAdminBook.vue' /* webpackChunkName: "components/modal-admin-book" */).then(c => wrapFunctional(c.default || c))
export const ModalAdminBooking = () => import('../../src/components/modals/admin/admin-booking/ModalAdminBooking.vue' /* webpackChunkName: "components/modal-admin-booking" */).then(c => wrapFunctional(c.default || c))
export const ModalAdminCheckout = () => import('../../src/components/modals/admin/admin-checkout/ModalAdminCheckout.vue' /* webpackChunkName: "components/modal-admin-checkout" */).then(c => wrapFunctional(c.default || c))
export const ModalAdminCredit = () => import('../../src/components/modals/admin/admin-credit/ModalAdminCredit.vue' /* webpackChunkName: "components/modal-admin-credit" */).then(c => wrapFunctional(c.default || c))
export const ModalAdminLink = () => import('../../src/components/modals/admin/admin-link/ModalAdminLink.vue' /* webpackChunkName: "components/modal-admin-link" */).then(c => wrapFunctional(c.default || c))
export const ModalAdminNewUser = () => import('../../src/components/modals/admin/admin-new-user/ModalAdminNewUser.vue' /* webpackChunkName: "components/modal-admin-new-user" */).then(c => wrapFunctional(c.default || c))
export const ModalAdminOrder = () => import('../../src/components/modals/admin/admin-order/ModalAdminOrder.vue' /* webpackChunkName: "components/modal-admin-order" */).then(c => wrapFunctional(c.default || c))
export const ModalImpersonate = () => import('../../src/components/modals/admin/impersonate/ModalImpersonate.vue' /* webpackChunkName: "components/modal-impersonate" */).then(c => wrapFunctional(c.default || c))
export const ModalOrderContent = () => import('../../src/components/modals/admin/order-content/ModalOrderContent.vue' /* webpackChunkName: "components/modal-order-content" */).then(c => wrapFunctional(c.default || c))
export const ModalSaveMaintenance = () => import('../../src/components/modals/admin/save-maintenance/ModalSaveMaintenance.vue' /* webpackChunkName: "components/modal-save-maintenance" */).then(c => wrapFunctional(c.default || c))
export const ModalUserNotificationActivity = () => import('../../src/components/modals/admin/user-notification-activity/ModalUserNotificationActivity.vue' /* webpackChunkName: "components/modal-user-notification-activity" */).then(c => wrapFunctional(c.default || c))
export const ModalViewAnnouncement = () => import('../../src/components/modals/admin/view-announcement/ModalViewAnnouncement.vue' /* webpackChunkName: "components/modal-view-announcement" */).then(c => wrapFunctional(c.default || c))
export const ModalBasket = () => import('../../src/components/modals/book/basket/ModalBasket.vue' /* webpackChunkName: "components/modal-basket" */).then(c => wrapFunctional(c.default || c))
export const ModalCancel = () => import('../../src/components/modals/book/cancel/ModalCancel.vue' /* webpackChunkName: "components/modal-cancel" */).then(c => wrapFunctional(c.default || c))
export const ModalConflict = () => import('../../src/components/modals/book/conflict/ModalConflict.vue' /* webpackChunkName: "components/modal-conflict" */).then(c => wrapFunctional(c.default || c))
export const ModalDiscount = () => import('../../src/components/modals/book/discount/ModalDiscount.vue' /* webpackChunkName: "components/modal-discount" */).then(c => wrapFunctional(c.default || c))
export const ModalExpired = () => import('../../src/components/modals/book/expired/ModalExpired.vue' /* webpackChunkName: "components/modal-expired" */).then(c => wrapFunctional(c.default || c))
export const ModalReschedule = () => import('../../src/components/modals/book/reschedule/ModalReschedule.vue' /* webpackChunkName: "components/modal-reschedule" */).then(c => wrapFunctional(c.default || c))
export const ModalCreated = () => import('../../src/components/modals/content/created/ModalCreated.vue' /* webpackChunkName: "components/modal-created" */).then(c => wrapFunctional(c.default || c))
export const ModalImageUpload = () => import('../../src/components/modals/content/image-upload/ModalImageUpload.vue' /* webpackChunkName: "components/modal-image-upload" */).then(c => wrapFunctional(c.default || c))
export const ModalAddToCalendar = () => import('../../src/components/modals/global/add-to-calendar/ModalAddToCalendar.vue' /* webpackChunkName: "components/modal-add-to-calendar" */).then(c => wrapFunctional(c.default || c))
export const ModalAppWaiting = () => import('../../src/components/modals/global/app-waiting/ModalAppWaiting.vue' /* webpackChunkName: "components/modal-app-waiting" */).then(c => wrapFunctional(c.default || c))
export const ModalConfirm = () => import('../../src/components/modals/global/confirm/ModalConfirm.vue' /* webpackChunkName: "components/modal-confirm" */).then(c => wrapFunctional(c.default || c))
export const ModalDebug = () => import('../../src/components/modals/global/debug/ModalDebug.vue' /* webpackChunkName: "components/modal-debug" */).then(c => wrapFunctional(c.default || c))
export const ModalDirections = () => import('../../src/components/modals/global/directions/ModalDirections.vue' /* webpackChunkName: "components/modal-directions" */).then(c => wrapFunctional(c.default || c))
export const ModalFaqs = () => import('../../src/components/modals/global/faqs/ModalFaqs.vue' /* webpackChunkName: "components/modal-faqs" */).then(c => wrapFunctional(c.default || c))
export const ModalFilter = () => import('../../src/components/modals/global/filters/ModalFilter.vue' /* webpackChunkName: "components/modal-filter" */).then(c => wrapFunctional(c.default || c))
export const ModalHelp = () => import('../../src/components/modals/global/help/ModalHelp.vue' /* webpackChunkName: "components/modal-help" */).then(c => wrapFunctional(c.default || c))
export const ModalHowItWorksVideo = () => import('../../src/components/modals/global/how-it-works-video/ModalHowItWorksVideo.vue' /* webpackChunkName: "components/modal-how-it-works-video" */).then(c => wrapFunctional(c.default || c))
export const ModalMobileMenu = () => import('../../src/components/modals/global/mobile-menu/ModalMobileMenu.vue' /* webpackChunkName: "components/modal-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const ModalNotifications = () => import('../../src/components/modals/global/notifications/ModalNotifications.vue' /* webpackChunkName: "components/modal-notifications" */).then(c => wrapFunctional(c.default || c))
export const ModalClosedJobs = () => import('../../src/components/modals/maintenance/closed-jobs/ModalClosedJobs.vue' /* webpackChunkName: "components/modal-closed-jobs" */).then(c => wrapFunctional(c.default || c))
export const ModalEditJob = () => import('../../src/components/modals/maintenance/edit-job/ModalEditJob.vue' /* webpackChunkName: "components/modal-edit-job" */).then(c => wrapFunctional(c.default || c))
export const ModalNewJob = () => import('../../src/components/modals/maintenance/new-job/ModalNewJob.vue' /* webpackChunkName: "components/modal-new-job" */).then(c => wrapFunctional(c.default || c))
export const ModalViewJob = () => import('../../src/components/modals/maintenance/view-job/ModalViewJob.vue' /* webpackChunkName: "components/modal-view-job" */).then(c => wrapFunctional(c.default || c))
export const ModalFarmTypes = () => import('../../src/components/modals/organisation/farm-types/ModalFarmTypes.vue' /* webpackChunkName: "components/modal-farm-types" */).then(c => wrapFunctional(c.default || c))
export const ModalInviteNew = () => import('../../src/components/modals/organisation/invite-new/ModalInviteNew.vue' /* webpackChunkName: "components/modal-invite-new" */).then(c => wrapFunctional(c.default || c))
export const ModalFindPaddock = () => import('../../src/components/modals/paddock/find-paddock/ModalFindPaddock.vue' /* webpackChunkName: "components/modal-find-paddock" */).then(c => wrapFunctional(c.default || c))
export const NavMobileFooter = () => import('../../src/components/nav/mobile/footer/NavMobileFooter.vue' /* webpackChunkName: "components/nav-mobile-footer" */).then(c => wrapFunctional(c.default || c))
export const NavMobileMenuItem = () => import('../../src/components/nav/mobile/menu-item/NavMobileMenuItem.vue' /* webpackChunkName: "components/nav-mobile-menu-item" */).then(c => wrapFunctional(c.default || c))
export const NavSidebarItem = () => import('../../src/components/nav/sidebar/item/NavSidebarItem.vue' /* webpackChunkName: "components/nav-sidebar-item" */).then(c => wrapFunctional(c.default || c))
export const IframeEmbedModal = () => import('../../src/components/utils/iframe-embed/iframeEmbedModal.vue/IframeEmbedModal.vue' /* webpackChunkName: "components/iframe-embed-modal" */).then(c => wrapFunctional(c.default || c))
export const LoaderOverlay = () => import('../../src/components/utils/loader/LoaderOverlay/LoaderOverlay.vue' /* webpackChunkName: "components/loader-overlay" */).then(c => wrapFunctional(c.default || c))
export const StepsHorizontal = () => import('../../src/components/elements/base/steps/horizontal/StepsHorizontal.vue' /* webpackChunkName: "components/steps-horizontal" */).then(c => wrapFunctional(c.default || c))
export const FieldMapper = () => import('../../src/components/elements/form/complex-fields/field-mapper/FieldMapper.vue' /* webpackChunkName: "components/field-mapper" */).then(c => wrapFunctional(c.default || c))
export const ManualAddressInput = () => import('../../src/components/elements/form/complex-fields/manual-address-input/ManualAddressInput.vue' /* webpackChunkName: "components/manual-address-input" */).then(c => wrapFunctional(c.default || c))
export const MapDirections = () => import('../../src/components/elements/geo/map/directions/MapDirections.vue' /* webpackChunkName: "components/map-directions" */).then(c => wrapFunctional(c.default || c))
export const MapFindPaddock = () => import('../../src/components/elements/geo/map/find-paddock/MapFindPaddock.vue' /* webpackChunkName: "components/map-find-paddock" */).then(c => wrapFunctional(c.default || c))
export const MapIcon = () => import('../../src/components/elements/geo/map/marker/MapIcon.vue' /* webpackChunkName: "components/map-icon" */).then(c => wrapFunctional(c.default || c))
export const ModalNewDiscount = () => import('../../src/components/modals/admin/discount/new-discount/ModalNewDiscount.vue' /* webpackChunkName: "components/modal-new-discount" */).then(c => wrapFunctional(c.default || c))
export const ModalFiltersHelp = () => import('../../src/components/modals/global/filters/filters-help/ModalFiltersHelp.vue' /* webpackChunkName: "components/modal-filters-help" */).then(c => wrapFunctional(c.default || c))
export const ModalCookies = () => import('../../src/components/modals/global/legal/cookies/ModalCookies.vue' /* webpackChunkName: "components/modal-cookies" */).then(c => wrapFunctional(c.default || c))
export const ModalPrivacy = () => import('../../src/components/modals/global/legal/privacy/ModalPrivacy.vue' /* webpackChunkName: "components/modal-privacy" */).then(c => wrapFunctional(c.default || c))
export const ModalTermsAndConditions = () => import('../../src/components/modals/global/legal/terms-and-conditions/ModalTermsAndConditions.vue' /* webpackChunkName: "components/modal-terms-and-conditions" */).then(c => wrapFunctional(c.default || c))
export const ButtonContact = () => import('../../src/components/elements/base/button/templates/contact/ButtonContact.vue' /* webpackChunkName: "components/button-contact" */).then(c => wrapFunctional(c.default || c))
export const ButtonFilter = () => import('../../src/components/elements/base/button/templates/filter/ButtonFilter.vue' /* webpackChunkName: "components/button-filter" */).then(c => wrapFunctional(c.default || c))
export const ButtonRefresh = () => import('../../src/components/elements/base/button/templates/refresh/ButtonRefresh.vue' /* webpackChunkName: "components/button-refresh" */).then(c => wrapFunctional(c.default || c))
export const PlaceholderAvatar = () => import('../../src/components/elements/base/placeholder/templates/avatar/PlaceholderAvatar.vue' /* webpackChunkName: "components/placeholder-avatar" */).then(c => wrapFunctional(c.default || c))
export const PlaceholderLoadList = () => import('../../src/components/elements/base/placeholder/templates/load-list/PlaceholderLoadList.vue' /* webpackChunkName: "components/placeholder-load-list" */).then(c => wrapFunctional(c.default || c))
export const PlaceholderMap = () => import('../../src/components/elements/base/placeholder/templates/map/PlaceholderMap.vue' /* webpackChunkName: "components/placeholder-map" */).then(c => wrapFunctional(c.default || c))
export const PlaceholderCardRow = () => import('../../src/components/elements/base/placeholder/templates/payment-cards/PlaceholderCardRow.vue' /* webpackChunkName: "components/placeholder-card-row" */).then(c => wrapFunctional(c.default || c))
export const FileUploadAvatar = () => import('../../src/components/elements/form/file-upload/templates/avatar/FileUploadAvatar.vue' /* webpackChunkName: "components/file-upload-avatar" */).then(c => wrapFunctional(c.default || c))
export const FileUpload = () => import('../../src/components/elements/form/file-upload/templates/default/FileUpload.vue' /* webpackChunkName: "components/file-upload" */).then(c => wrapFunctional(c.default || c))
export const FileUploadSingle = () => import('../../src/components/elements/form/file-upload/templates/single/FileUploadSingle.vue' /* webpackChunkName: "components/file-upload-single" */).then(c => wrapFunctional(c.default || c))
export const InputAddressLookup = () => import('../../src/components/elements/form/input/templates/address-lookup/InputAddressLookup.vue' /* webpackChunkName: "components/input-address-lookup" */).then(c => wrapFunctional(c.default || c))
export const InputColorPicker = () => import('../../src/components/elements/form/input/templates/color-picker/InputColorPicker.vue' /* webpackChunkName: "components/input-color-picker" */).then(c => wrapFunctional(c.default || c))
export const InputCurrency = () => import('../../src/components/elements/form/input/templates/currency/InputCurrency.vue' /* webpackChunkName: "components/input-currency" */).then(c => wrapFunctional(c.default || c))
export const FieldPassword = () => import('../../src/components/elements/form/input/templates/password/FieldPassword.vue' /* webpackChunkName: "components/field-password" */).then(c => wrapFunctional(c.default || c))
export const InputSearch = () => import('../../src/components/elements/form/input/templates/search/InputSearch.vue' /* webpackChunkName: "components/input-search" */).then(c => wrapFunctional(c.default || c))
export const InputTelephone = () => import('../../src/components/elements/form/input/templates/telephone/InputTelephone.vue' /* webpackChunkName: "components/input-telephone" */).then(c => wrapFunctional(c.default || c))
export const InputWhat3Words = () => import('../../src/components/elements/form/input/templates/w3w-lookup/InputWhat3Words.vue' /* webpackChunkName: "components/input-what3-words" */).then(c => wrapFunctional(c.default || c))
export const InputWeight = () => import('../../src/components/elements/form/input/templates/weight/InputWeight.vue' /* webpackChunkName: "components/input-weight" */).then(c => wrapFunctional(c.default || c))
export const InputSearchAutocomplete = () => import('../../src/components/elements/form/input/templates/search/autocomplete/InputSearchAutocomplete.vue' /* webpackChunkName: "components/input-search-autocomplete" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1fe93fba = () => interopDefault(import('../src/pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _2f153adc = () => interopDefault(import('../src/pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _d8bb252a = () => interopDefault(import('../src/pages/app.vue' /* webpackChunkName: "pages/app" */))
const _de63855e = () => interopDefault(import('../src/pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _73cfbd52 = () => interopDefault(import('../src/pages/book/index.vue' /* webpackChunkName: "pages/book/index" */))
const _0f3dd22c = () => interopDefault(import('../src/pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _59409594 = () => interopDefault(import('../src/pages/dog-wash.vue' /* webpackChunkName: "pages/dog-wash" */))
const _5817bd7a = () => interopDefault(import('../src/pages/faqs.vue' /* webpackChunkName: "pages/faqs" */))
const _3274bbcc = () => interopDefault(import('../src/pages/gift-vouchers.vue' /* webpackChunkName: "pages/gift-vouchers" */))
const _a9adf73a = () => interopDefault(import('../src/pages/how-it-works.vue' /* webpackChunkName: "pages/how-it-works" */))
const _4e6811e8 = () => interopDefault(import('../src/pages/land-owners.vue' /* webpackChunkName: "pages/land-owners" */))
const _8af39afa = () => interopDefault(import('../src/pages/links.vue' /* webpackChunkName: "pages/links" */))
const _7f23e580 = () => interopDefault(import('../src/pages/locale.vue' /* webpackChunkName: "pages/locale" */))
const _470a6946 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _54257074 = () => interopDefault(import('../src/pages/mobile-menu.vue' /* webpackChunkName: "pages/mobile-menu" */))
const _c46c9e88 = () => interopDefault(import('../src/pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _01b9ee56 = () => interopDefault(import('../src/pages/paddocks/index.vue' /* webpackChunkName: "pages/paddocks/index" */))
const _67df0f5c = () => interopDefault(import('../src/pages/platform/index.vue' /* webpackChunkName: "pages/platform/index" */))
const _ede082ec = () => interopDefault(import('../src/pages/privacy-and-cookie-policy.vue' /* webpackChunkName: "pages/privacy-and-cookie-policy" */))
const _53271dc2 = () => interopDefault(import('../src/pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _3a3b6b83 = () => interopDefault(import('../src/pages/team.vue' /* webpackChunkName: "pages/team" */))
const _a0a6c9cc = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _ea061fa4 = () => interopDefault(import('../src/pages/account/settings/index.vue' /* webpackChunkName: "pages/account/settings/index" */))
const _2573ab7c = () => interopDefault(import('../src/pages/account/settings/index/cards.vue' /* webpackChunkName: "pages/account/settings/index/cards" */))
const _2958d647 = () => interopDefault(import('../src/pages/account/settings/index/notifications.vue' /* webpackChunkName: "pages/account/settings/index/notifications" */))
const _30d43870 = () => interopDefault(import('../src/pages/account/settings/index/profile.vue' /* webpackChunkName: "pages/account/settings/index/profile" */))
const _15ff329e = () => interopDefault(import('../src/pages/account/settings/index/security.vue' /* webpackChunkName: "pages/account/settings/index/security" */))
const _7c01ddec = () => interopDefault(import('../src/pages/admin/adverts/index.vue' /* webpackChunkName: "pages/admin/adverts/index" */))
const _38b9e5ce = () => interopDefault(import('../src/pages/admin/announcements/index.vue' /* webpackChunkName: "pages/admin/announcements/index" */))
const _48d4b4a7 = () => interopDefault(import('../src/pages/admin/calendar/index.vue' /* webpackChunkName: "pages/admin/calendar/index" */))
const _f45dc274 = () => interopDefault(import('../src/pages/admin/content/index.vue' /* webpackChunkName: "pages/admin/content/index" */))
const _ab8521bc = () => interopDefault(import('../src/pages/admin/customers/index.vue' /* webpackChunkName: "pages/admin/customers/index" */))
const _71636442 = () => interopDefault(import('../src/pages/admin/discounts/index.vue' /* webpackChunkName: "pages/admin/discounts/index" */))
const _8d11c2f4 = () => interopDefault(import('../src/pages/admin/links/index.vue' /* webpackChunkName: "pages/admin/links/index" */))
const _80cb4840 = () => interopDefault(import('../src/pages/admin/maintenance/index.vue' /* webpackChunkName: "pages/admin/maintenance/index" */))
const _7d8e2e64 = () => interopDefault(import('../src/pages/admin/maintenance-dashboard.vue' /* webpackChunkName: "pages/admin/maintenance-dashboard" */))
const _193318e9 = () => interopDefault(import('../src/pages/admin/map/index.vue' /* webpackChunkName: "pages/admin/map/index" */))
const _7db21cb7 = () => interopDefault(import('../src/pages/admin/marketing-dashboard.vue' /* webpackChunkName: "pages/admin/marketing-dashboard" */))
const _57e720b4 = () => interopDefault(import('../src/pages/admin/organisations/index.vue' /* webpackChunkName: "pages/admin/organisations/index" */))
const _99fa7ad4 = () => interopDefault(import('../src/pages/admin/paddocks/index.vue' /* webpackChunkName: "pages/admin/paddocks/index" */))
const _7060dc8c = () => interopDefault(import('../src/pages/admin/reports/index.vue' /* webpackChunkName: "pages/admin/reports/index" */))
const _6663c09e = () => interopDefault(import('../src/pages/admin/reports/index/all.vue' /* webpackChunkName: "pages/admin/reports/index/all" */))
const _7d5449ce = () => interopDefault(import('../src/pages/admin/reports/index/revenue.vue' /* webpackChunkName: "pages/admin/reports/index/revenue" */))
const _60474968 = () => interopDefault(import('../src/pages/admin/settings/index.vue' /* webpackChunkName: "pages/admin/settings/index" */))
const _50415e34 = () => interopDefault(import('../src/pages/admin/settings/index/profile.vue' /* webpackChunkName: "pages/admin/settings/index/profile" */))
const _0de49d53 = () => interopDefault(import('../src/pages/admin/settings/index/security.vue' /* webpackChunkName: "pages/admin/settings/index/security" */))
const _249e2b82 = () => interopDefault(import('../src/pages/admin/settings/index/system.vue' /* webpackChunkName: "pages/admin/settings/index/system" */))
const _0fb8f806 = () => interopDefault(import('../src/pages/admin/team/index.vue' /* webpackChunkName: "pages/admin/team/index" */))
const _00254044 = () => interopDefault(import('../src/pages/auth/forgot-password.vue' /* webpackChunkName: "pages/auth/forgot-password" */))
const _5a75e068 = () => interopDefault(import('../src/pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _4157351b = () => interopDefault(import('../src/pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _b06bff0c = () => interopDefault(import('../src/pages/auth/native-callback.vue' /* webpackChunkName: "pages/auth/native-callback" */))
const _ae000650 = () => interopDefault(import('../src/pages/auth/organisations.vue' /* webpackChunkName: "pages/auth/organisations" */))
const _36c29ef7 = () => interopDefault(import('../src/pages/auth/register/index.vue' /* webpackChunkName: "pages/auth/register/index" */))
const _ba1b834c = () => interopDefault(import('../src/pages/auth/social-login.vue' /* webpackChunkName: "pages/auth/social-login" */))
const _4e49fef9 = () => interopDefault(import('../src/pages/book/checkout/index.vue' /* webpackChunkName: "pages/book/checkout/index" */))
const _3bd3ad12 = () => interopDefault(import('../src/pages/platform/book/index.vue' /* webpackChunkName: "pages/platform/book/index" */))
const _77fead69 = () => interopDefault(import('../src/pages/platform/map/index.vue' /* webpackChunkName: "pages/platform/map/index" */))
const _65cda07c = () => interopDefault(import('../src/pages/platform/news/index.vue' /* webpackChunkName: "pages/platform/news/index" */))
const _42bffe16 = () => interopDefault(import('../src/pages/platform/paddocks/index.vue' /* webpackChunkName: "pages/platform/paddocks/index" */))
const _5f9996cc = () => interopDefault(import('../src/pages/platform/settings/index.vue' /* webpackChunkName: "pages/platform/settings/index" */))
const _65323b40 = () => interopDefault(import('../src/pages/platform/settings/index/cards.vue' /* webpackChunkName: "pages/platform/settings/index/cards" */))
const _79469065 = () => interopDefault(import('../src/pages/platform/settings/index/notifications.vue' /* webpackChunkName: "pages/platform/settings/index/notifications" */))
const _42690c66 = () => interopDefault(import('../src/pages/platform/settings/index/profile.vue' /* webpackChunkName: "pages/platform/settings/index/profile" */))
const _16db5c5a = () => interopDefault(import('../src/pages/platform/settings/index/security.vue' /* webpackChunkName: "pages/platform/settings/index/security" */))
const _69ee4fe9 = () => interopDefault(import('../src/pages/platform/sharing/index.vue' /* webpackChunkName: "pages/platform/sharing/index" */))
const _c31f3e42 = () => interopDefault(import('../src/pages/platform/shop/index.vue' /* webpackChunkName: "pages/platform/shop/index" */))
const _1aa3a9b4 = () => interopDefault(import('../src/pages/platform/surveys/index.vue' /* webpackChunkName: "pages/platform/surveys/index" */))
const _63ca60a4 = () => interopDefault(import('../src/pages/platform/vouchers/index.vue' /* webpackChunkName: "pages/platform/vouchers/index" */))
const _47bc403a = () => interopDefault(import('../src/pages/admin/adverts/new.vue' /* webpackChunkName: "pages/admin/adverts/new" */))
const _f4eb47c8 = () => interopDefault(import('../src/pages/admin/content/faqs/index.vue' /* webpackChunkName: "pages/admin/content/faqs/index" */))
const _50505d9f = () => interopDefault(import('../src/pages/admin/content/faqs/index/index.vue' /* webpackChunkName: "pages/admin/content/faqs/index/index" */))
const _7caea352 = () => interopDefault(import('../src/pages/admin/content/news/index.vue' /* webpackChunkName: "pages/admin/content/news/index" */))
const _5f73e455 = () => interopDefault(import('../src/pages/admin/content/news/index/index.vue' /* webpackChunkName: "pages/admin/content/news/index/index" */))
const _c2fdb108 = () => interopDefault(import('../src/pages/admin/content/privacy.vue' /* webpackChunkName: "pages/admin/content/privacy" */))
const _2fcedcfb = () => interopDefault(import('../src/pages/admin/content/terms.vue' /* webpackChunkName: "pages/admin/content/terms" */))
const _3b59c788 = () => interopDefault(import('../src/pages/admin/customers/merge.vue' /* webpackChunkName: "pages/admin/customers/merge" */))
const _1a7e41d0 = () => interopDefault(import('../src/pages/admin/discounts/gifts.vue' /* webpackChunkName: "pages/admin/discounts/gifts" */))
const _79cf3c30 = () => interopDefault(import('../src/pages/admin/discounts/image.vue' /* webpackChunkName: "pages/admin/discounts/image" */))
const _6a464158 = () => interopDefault(import('../src/pages/admin/links/new.vue' /* webpackChunkName: "pages/admin/links/new" */))
const _00224764 = () => interopDefault(import('../src/pages/admin/paddocks/new.vue' /* webpackChunkName: "pages/admin/paddocks/new" */))
const _495eaeab = () => interopDefault(import('../src/pages/auth/register/business.vue' /* webpackChunkName: "pages/auth/register/business" */))
const _1e6b7ccc = () => interopDefault(import('../src/pages/book/checkout/register.vue' /* webpackChunkName: "pages/book/checkout/register" */))
const _645f8136 = () => interopDefault(import('../src/pages/platform/book/checkout.vue' /* webpackChunkName: "pages/platform/book/checkout" */))
const _76a2eb2c = () => interopDefault(import('../src/pages/admin/content/faqs/new.vue' /* webpackChunkName: "pages/admin/content/faqs/new" */))
const _0f872720 = () => interopDefault(import('../src/pages/admin/content/news/new.vue' /* webpackChunkName: "pages/admin/content/news/new" */))
const _2bac51c8 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0d9f8482 = () => interopDefault(import('../src/pages/account/book/complete/_orderId.vue' /* webpackChunkName: "pages/account/book/complete/_orderId" */))
const _9e0e94f8 = () => interopDefault(import('../src/pages/admin/content/faqs/_id.vue' /* webpackChunkName: "pages/admin/content/faqs/_id" */))
const _085d5b8c = () => interopDefault(import('../src/pages/admin/content/news/_id.vue' /* webpackChunkName: "pages/admin/content/news/_id" */))
const _6872ad20 = () => interopDefault(import('../src/pages/platform/book/complete/_orderId.vue' /* webpackChunkName: "pages/platform/book/complete/_orderId" */))
const _979889da = () => interopDefault(import('../src/pages/account/booking/_bookingId.vue' /* webpackChunkName: "pages/account/booking/_bookingId" */))
const _0f10a19a = () => interopDefault(import('../src/pages/account/order/_orderId.vue' /* webpackChunkName: "pages/account/order/_orderId" */))
const _4740d20a = () => interopDefault(import('../src/pages/admin/customers/_id.vue' /* webpackChunkName: "pages/admin/customers/_id" */))
const _008fd8a4 = () => interopDefault(import('../src/pages/admin/customers/_id/activity.vue' /* webpackChunkName: "pages/admin/customers/_id/activity" */))
const _119fdb4f = () => interopDefault(import('../src/pages/admin/customers/_id/bookings.vue' /* webpackChunkName: "pages/admin/customers/_id/bookings" */))
const _1ed7f264 = () => interopDefault(import('../src/pages/admin/customers/_id/credit.vue' /* webpackChunkName: "pages/admin/customers/_id/credit" */))
const _3853b6c2 = () => interopDefault(import('../src/pages/admin/customers/_id/edit.vue' /* webpackChunkName: "pages/admin/customers/_id/edit" */))
const _756371ba = () => interopDefault(import('../src/pages/admin/customers/_id/notifications.vue' /* webpackChunkName: "pages/admin/customers/_id/notifications" */))
const _45a58e0e = () => interopDefault(import('../src/pages/admin/customers/_id/overview.vue' /* webpackChunkName: "pages/admin/customers/_id/overview" */))
const _7694bbe7 = () => interopDefault(import('../src/pages/admin/customers/_id/replays.vue' /* webpackChunkName: "pages/admin/customers/_id/replays" */))
const _67843c07 = () => interopDefault(import('../src/pages/admin/discounts/_id.vue' /* webpackChunkName: "pages/admin/discounts/_id" */))
const _07a75b47 = () => interopDefault(import('../src/pages/admin/discounts/_id/activity.vue' /* webpackChunkName: "pages/admin/discounts/_id/activity" */))
const _520670c2 = () => interopDefault(import('../src/pages/admin/discounts/_id/edit.vue' /* webpackChunkName: "pages/admin/discounts/_id/edit" */))
const _19c3b8e4 = () => interopDefault(import('../src/pages/admin/organisations/_id.vue' /* webpackChunkName: "pages/admin/organisations/_id" */))
const _e00808c0 = () => interopDefault(import('../src/pages/admin/organisations/_id/activity.vue' /* webpackChunkName: "pages/admin/organisations/_id/activity" */))
const _55dc9dec = () => interopDefault(import('../src/pages/admin/organisations/_id/overview.vue' /* webpackChunkName: "pages/admin/organisations/_id/overview" */))
const _60b9bc72 = () => interopDefault(import('../src/pages/admin/organisations/_id/users.vue' /* webpackChunkName: "pages/admin/organisations/_id/users" */))
const _27271b04 = () => interopDefault(import('../src/pages/admin/paddocks/_id.vue' /* webpackChunkName: "pages/admin/paddocks/_id" */))
const _e23ea6aa = () => interopDefault(import('../src/pages/admin/paddocks/_id/edit.vue' /* webpackChunkName: "pages/admin/paddocks/_id/edit" */))
const _4e559fcc = () => interopDefault(import('../src/pages/admin/paddocks/_id/overview.vue' /* webpackChunkName: "pages/admin/paddocks/_id/overview" */))
const _77ef1638 = () => interopDefault(import('../src/pages/admin/paddocks/_id/schedule.vue' /* webpackChunkName: "pages/admin/paddocks/_id/schedule" */))
const _437f8fce = () => interopDefault(import('../src/pages/admin/paddocks/_id/stats.vue' /* webpackChunkName: "pages/admin/paddocks/_id/stats" */))
const _5e5164f2 = () => interopDefault(import('../src/pages/auth/invited/_verificationCode.vue' /* webpackChunkName: "pages/auth/invited/_verificationCode" */))
const _55c51cb6 = () => interopDefault(import('../src/pages/auth/reset-password/_verificationCode/index.vue' /* webpackChunkName: "pages/auth/reset-password/_verificationCode/index" */))
const _3f78a158 = () => interopDefault(import('../src/pages/auth/verify-email/_verificationCode/index.vue' /* webpackChunkName: "pages/auth/verify-email/_verificationCode/index" */))
const _3a1d0bca = () => interopDefault(import('../src/pages/platform/book/_slug.vue' /* webpackChunkName: "pages/platform/book/_slug" */))
const _4782f9e4 = () => interopDefault(import('../src/pages/platform/booking/_id.vue' /* webpackChunkName: "pages/platform/booking/_id" */))
const _07ce7183 = () => interopDefault(import('../src/pages/platform/order/_id.vue' /* webpackChunkName: "pages/platform/order/_id" */))
const _41095cce = () => interopDefault(import('../src/pages/platform/paddocks/_slug.vue' /* webpackChunkName: "pages/platform/paddocks/_slug" */))
const _081c92df = () => interopDefault(import('../src/pages/platform/news/_slug/_id.vue' /* webpackChunkName: "pages/platform/news/_slug/_id" */))
const _72191c0a = () => interopDefault(import('../src/pages/book/_slug.vue' /* webpackChunkName: "pages/book/_slug" */))
const _00034d0e = () => interopDefault(import('../src/pages/paddocks/_slug.vue' /* webpackChunkName: "pages/paddocks/_slug" */))
const _71d2f9c2 = () => interopDefault(import('../src/pages/news/_slug/_id.vue' /* webpackChunkName: "pages/news/_slug/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _1fe93fba,
    name: "account"
  }, {
    path: "/admin",
    component: _2f153adc,
    name: "admin"
  }, {
    path: "/app",
    component: _d8bb252a,
    name: "app"
  }, {
    path: "/auth",
    component: _de63855e,
    name: "auth"
  }, {
    path: "/book",
    component: _73cfbd52,
    name: "book"
  }, {
    path: "/contact",
    component: _0f3dd22c,
    name: "contact"
  }, {
    path: "/dog-wash",
    component: _59409594,
    name: "dog-wash"
  }, {
    path: "/faqs",
    component: _5817bd7a,
    name: "faqs"
  }, {
    path: "/gift-vouchers",
    component: _3274bbcc,
    name: "gift-vouchers"
  }, {
    path: "/how-it-works",
    component: _a9adf73a,
    name: "how-it-works"
  }, {
    path: "/land-owners",
    component: _4e6811e8,
    name: "land-owners"
  }, {
    path: "/links",
    component: _8af39afa,
    name: "links"
  }, {
    path: "/locale",
    component: _7f23e580,
    name: "locale"
  }, {
    path: "/maintenance",
    component: _470a6946,
    name: "maintenance"
  }, {
    path: "/mobile-menu",
    component: _54257074,
    name: "mobile-menu"
  }, {
    path: "/news",
    component: _c46c9e88,
    name: "news"
  }, {
    path: "/paddocks",
    component: _01b9ee56,
    name: "paddocks"
  }, {
    path: "/platform",
    component: _67df0f5c,
    name: "platform"
  }, {
    path: "/privacy-and-cookie-policy",
    component: _ede082ec,
    name: "privacy-and-cookie-policy"
  }, {
    path: "/shop",
    component: _53271dc2,
    name: "shop"
  }, {
    path: "/team",
    component: _3a3b6b83,
    name: "team"
  }, {
    path: "/terms-and-conditions",
    component: _a0a6c9cc,
    name: "terms-and-conditions"
  }, {
    path: "/account/settings",
    component: _ea061fa4,
    name: "account-settings",
    children: [{
      path: "cards",
      component: _2573ab7c,
      name: "account-settings-index-cards"
    }, {
      path: "notifications",
      component: _2958d647,
      name: "account-settings-index-notifications"
    }, {
      path: "profile",
      component: _30d43870,
      name: "account-settings-index-profile"
    }, {
      path: "security",
      component: _15ff329e,
      name: "account-settings-index-security"
    }]
  }, {
    path: "/admin/adverts",
    component: _7c01ddec,
    name: "admin-adverts"
  }, {
    path: "/admin/announcements",
    component: _38b9e5ce,
    name: "admin-announcements"
  }, {
    path: "/admin/calendar",
    component: _48d4b4a7,
    name: "admin-calendar"
  }, {
    path: "/admin/content",
    component: _f45dc274,
    name: "admin-content"
  }, {
    path: "/admin/customers",
    component: _ab8521bc,
    name: "admin-customers"
  }, {
    path: "/admin/discounts",
    component: _71636442,
    name: "admin-discounts"
  }, {
    path: "/admin/links",
    component: _8d11c2f4,
    name: "admin-links"
  }, {
    path: "/admin/maintenance",
    component: _80cb4840,
    name: "admin-maintenance"
  }, {
    path: "/admin/maintenance-dashboard",
    component: _7d8e2e64,
    name: "admin-maintenance-dashboard"
  }, {
    path: "/admin/map",
    component: _193318e9,
    name: "admin-map"
  }, {
    path: "/admin/marketing-dashboard",
    component: _7db21cb7,
    name: "admin-marketing-dashboard"
  }, {
    path: "/admin/organisations",
    component: _57e720b4,
    name: "admin-organisations"
  }, {
    path: "/admin/paddocks",
    component: _99fa7ad4,
    name: "admin-paddocks"
  }, {
    path: "/admin/reports",
    component: _7060dc8c,
    name: "admin-reports",
    children: [{
      path: "all",
      component: _6663c09e,
      name: "admin-reports-index-all"
    }, {
      path: "revenue",
      component: _7d5449ce,
      name: "admin-reports-index-revenue"
    }]
  }, {
    path: "/admin/settings",
    component: _60474968,
    name: "admin-settings",
    children: [{
      path: "profile",
      component: _50415e34,
      name: "admin-settings-index-profile"
    }, {
      path: "security",
      component: _0de49d53,
      name: "admin-settings-index-security"
    }, {
      path: "system",
      component: _249e2b82,
      name: "admin-settings-index-system"
    }]
  }, {
    path: "/admin/team",
    component: _0fb8f806,
    name: "admin-team"
  }, {
    path: "/auth/forgot-password",
    component: _00254044,
    name: "auth-forgot-password"
  }, {
    path: "/auth/login",
    component: _5a75e068,
    name: "auth-login"
  }, {
    path: "/auth/logout",
    component: _4157351b,
    name: "auth-logout"
  }, {
    path: "/auth/native-callback",
    component: _b06bff0c,
    name: "auth-native-callback"
  }, {
    path: "/auth/organisations",
    component: _ae000650,
    name: "auth-organisations"
  }, {
    path: "/auth/register",
    component: _36c29ef7,
    name: "auth-register"
  }, {
    path: "/auth/social-login",
    component: _ba1b834c,
    name: "auth-social-login"
  }, {
    path: "/book/checkout",
    component: _4e49fef9,
    name: "book-checkout"
  }, {
    path: "/platform/book",
    component: _3bd3ad12,
    name: "platform-book"
  }, {
    path: "/platform/map",
    component: _77fead69,
    name: "platform-map"
  }, {
    path: "/platform/news",
    component: _65cda07c,
    name: "platform-news"
  }, {
    path: "/platform/paddocks",
    component: _42bffe16,
    name: "platform-paddocks"
  }, {
    path: "/platform/settings",
    component: _5f9996cc,
    name: "platform-settings",
    children: [{
      path: "cards",
      component: _65323b40,
      name: "platform-settings-index-cards"
    }, {
      path: "notifications",
      component: _79469065,
      name: "platform-settings-index-notifications"
    }, {
      path: "profile",
      component: _42690c66,
      name: "platform-settings-index-profile"
    }, {
      path: "security",
      component: _16db5c5a,
      name: "platform-settings-index-security"
    }]
  }, {
    path: "/platform/sharing",
    component: _69ee4fe9,
    name: "platform-sharing"
  }, {
    path: "/platform/shop",
    component: _c31f3e42,
    name: "platform-shop"
  }, {
    path: "/platform/surveys",
    component: _1aa3a9b4,
    name: "platform-surveys"
  }, {
    path: "/platform/vouchers",
    component: _63ca60a4,
    name: "platform-vouchers"
  }, {
    path: "/admin/adverts/new",
    component: _47bc403a,
    name: "admin-adverts-new"
  }, {
    path: "/admin/content/faqs",
    component: _f4eb47c8,
    children: [{
      path: "",
      component: _50505d9f,
      name: "admin-content-faqs-index"
    }]
  }, {
    path: "/admin/content/news",
    component: _7caea352,
    children: [{
      path: "",
      component: _5f73e455,
      name: "admin-content-news-index"
    }]
  }, {
    path: "/admin/content/privacy",
    component: _c2fdb108,
    name: "admin-content-privacy"
  }, {
    path: "/admin/content/terms",
    component: _2fcedcfb,
    name: "admin-content-terms"
  }, {
    path: "/admin/customers/merge",
    component: _3b59c788,
    name: "admin-customers-merge"
  }, {
    path: "/admin/discounts/gifts",
    component: _1a7e41d0,
    name: "admin-discounts-gifts"
  }, {
    path: "/admin/discounts/image",
    component: _79cf3c30,
    name: "admin-discounts-image"
  }, {
    path: "/admin/links/new",
    component: _6a464158,
    name: "admin-links-new"
  }, {
    path: "/admin/paddocks/new",
    component: _00224764,
    name: "admin-paddocks-new"
  }, {
    path: "/auth/register/business",
    component: _495eaeab,
    name: "auth-register-business"
  }, {
    path: "/book/checkout/register",
    component: _1e6b7ccc,
    name: "book-checkout-register"
  }, {
    path: "/platform/book/checkout",
    component: _645f8136,
    name: "platform-book-checkout"
  }, {
    path: "/admin/content/faqs/new",
    component: _76a2eb2c,
    name: "admin-content-faqs-new"
  }, {
    path: "/admin/content/news/new",
    component: _0f872720,
    name: "admin-content-news-new"
  }, {
    path: "/",
    component: _2bac51c8,
    name: "index"
  }, {
    path: "/account/book/complete/:orderId?",
    component: _0d9f8482,
    name: "account-book-complete-orderId"
  }, {
    path: "/admin/content/faqs/:id",
    component: _9e0e94f8,
    name: "admin-content-faqs-id"
  }, {
    path: "/admin/content/news/:id",
    component: _085d5b8c,
    name: "admin-content-news-id"
  }, {
    path: "/platform/book/complete/:orderId?",
    component: _6872ad20,
    name: "platform-book-complete-orderId"
  }, {
    path: "/account/booking/:bookingId?",
    component: _979889da,
    name: "account-booking-bookingId"
  }, {
    path: "/account/order/:orderId?",
    component: _0f10a19a,
    name: "account-order-orderId"
  }, {
    path: "/admin/customers/:id",
    component: _4740d20a,
    name: "admin-customers-id",
    children: [{
      path: "activity",
      component: _008fd8a4,
      name: "admin-customers-id-activity"
    }, {
      path: "bookings",
      component: _119fdb4f,
      name: "admin-customers-id-bookings"
    }, {
      path: "credit",
      component: _1ed7f264,
      name: "admin-customers-id-credit"
    }, {
      path: "edit",
      component: _3853b6c2,
      name: "admin-customers-id-edit"
    }, {
      path: "notifications",
      component: _756371ba,
      name: "admin-customers-id-notifications"
    }, {
      path: "overview",
      component: _45a58e0e,
      name: "admin-customers-id-overview"
    }, {
      path: "replays",
      component: _7694bbe7,
      name: "admin-customers-id-replays"
    }]
  }, {
    path: "/admin/discounts/:id",
    component: _67843c07,
    name: "admin-discounts-id",
    children: [{
      path: "activity",
      component: _07a75b47,
      name: "admin-discounts-id-activity"
    }, {
      path: "edit",
      component: _520670c2,
      name: "admin-discounts-id-edit"
    }]
  }, {
    path: "/admin/organisations/:id",
    component: _19c3b8e4,
    name: "admin-organisations-id",
    children: [{
      path: "activity",
      component: _e00808c0,
      name: "admin-organisations-id-activity"
    }, {
      path: "overview",
      component: _55dc9dec,
      name: "admin-organisations-id-overview"
    }, {
      path: "users",
      component: _60b9bc72,
      name: "admin-organisations-id-users"
    }]
  }, {
    path: "/admin/paddocks/:id",
    component: _27271b04,
    name: "admin-paddocks-id",
    children: [{
      path: "edit",
      component: _e23ea6aa,
      name: "admin-paddocks-id-edit"
    }, {
      path: "overview",
      component: _4e559fcc,
      name: "admin-paddocks-id-overview"
    }, {
      path: "schedule",
      component: _77ef1638,
      name: "admin-paddocks-id-schedule"
    }, {
      path: "stats",
      component: _437f8fce,
      name: "admin-paddocks-id-stats"
    }]
  }, {
    path: "/auth/invited/:verificationCode?",
    component: _5e5164f2,
    name: "auth-invited-verificationCode"
  }, {
    path: "/auth/reset-password/:verificationCode",
    component: _55c51cb6,
    name: "auth-reset-password-verificationCode"
  }, {
    path: "/auth/verify-email/:verificationCode",
    component: _3f78a158,
    name: "auth-verify-email-verificationCode"
  }, {
    path: "/platform/book/:slug",
    component: _3a1d0bca,
    name: "platform-book-slug"
  }, {
    path: "/platform/booking/:id?",
    component: _4782f9e4,
    name: "platform-booking-id"
  }, {
    path: "/platform/order/:id?",
    component: _07ce7183,
    name: "platform-order-id"
  }, {
    path: "/platform/paddocks/:slug",
    component: _41095cce,
    name: "platform-paddocks-slug"
  }, {
    path: "/platform/news/:slug/:id?",
    component: _081c92df,
    name: "platform-news-slug-id"
  }, {
    path: "/book/:slug",
    component: _72191c0a,
    name: "book-slug"
  }, {
    path: "/paddocks/:slug",
    component: _00034d0e,
    name: "paddocks-slug"
  }, {
    path: "/news/:slug/:id?",
    component: _71d2f9c2,
    name: "news-slug-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

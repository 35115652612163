import { PlatformApi } from '~/services/api/platform'

export class AdvertService extends PlatformApi {
  async create() {
    return await this.post(`/advert`)
  }

  async getById(id) {
    return await this.get(`/advert/${id}`)
  }

  getAvailable({ paddockId }) {
    return this.get(`/advert/available?paddockId=${paddockId}`)
  }

  async update(id) {
    return await this.put(`/advert/${id}`)
  }

  async deleteAdvert(id) {
    return await this.delete(`/advert/${id}`)
  }

  logClick(id) {
    return this.post(`/advert/${id}/click`)
  }

  logImpression(id) {
    return this.post(`/advert/${id}/impression`)
  }
}

import { render, staticRenderFns } from "./Brochureware.vue?vue&type=template&id=09f5549e&scoped=true"
import script from "./Brochureware.vue?vue&type=script&lang=js"
export * from "./Brochureware.vue?vue&type=script&lang=js"
import style0 from "./Brochureware.vue?vue&type=style&index=0&id=09f5549e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09f5549e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Link: require('/home/runner/work/platform/platform/packages/client/src/components/elements/base/link/Link.vue').default,Img: require('/home/runner/work/platform/platform/packages/client/src/components/elements/base/image/Img.vue').default,BadgeCount: require('/home/runner/work/platform/platform/packages/client/src/components/elements/base/badge-count/BadgeCount.vue').default,Button: require('/home/runner/work/platform/platform/packages/client/src/components/elements/base/button/Button.vue').default,Banner: require('/home/runner/work/platform/platform/packages/client/src/components/elements/base/banner/Banner.vue').default,BannerGlobal: require('/home/runner/work/platform/platform/packages/client/src/components/banners/BannerGlobal.vue').default,Heading: require('/home/runner/work/platform/platform/packages/client/src/components/elements/typography/heading/Heading.vue').default,SocialLinks: require('/home/runner/work/platform/platform/packages/client/src/components/elements/social/SocialLinks.vue').default,CookieBanner: require('/home/runner/work/platform/platform/packages/client/src/components/elements/legal/cookie/CookieBanner.vue').default})
